/* You can add global styles to this file, and also import other style files */
@import "sass/theme";


/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/_variables";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";

@import 'node_modules/ngx-toastr/toastr';

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: var(--background-color, $background-color);
}


/* images*/
img {
  max-width: 100%;
  height: auto;
}

.overflowtext {
  overflow-wrap: anywhere;
}

.multiline {
  white-space: pre-wrap;
}


/*navbar*/
.colorednavbar {
  background-color: var(--brand-primary, $brand-primary);
}

.textnavbar {
  color: white !important;
  size: A3;
}

.texthome {
  color: black !important;
  size: A3;
}

.text-warning {
  color: $warning
}

.listitemhome{
  color: black !important;
  size: A3;
  padding: 1ch;
}

.navbar-dark .navbar-nav .show>.nav-link, .navbar-dark .navbar-nav {
  color: var(--brand-primary, $brand-primary);
  cursor: default;
}

.navbar-dark .navbar-nav .show>.nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: white;
  cursor: default;
}

/* dividers */

.hr-fahrkosten {
  height:2px;
  margin-top:2rem;
  margin-bottom:2rem;
}


/*links*/
a:link, a:visited {
  color: var(--brand-primary, $brand-primary);
}

a:hover, a:active {
  color: var(--brand-primary-dark, $brand-primary-dark) ;
}

/* buttons */
.btn-primary {
  color: white;
  background-color: var(--brand-primary, $brand-primary);
  border-color: var(--brand-primary-lighter, $brand-primary-lighter);
}

.btn-primary:active, .btn-primary:hover, .btn-primary:focus , a:active {
  color: white;
  background-color: var(--brand-primary-medium, $brand-primary-medium);
  border-color: var(--brand-primary-lighter, $brand-primary-lighter);
  cursor: pointer;
}

.btn-primary:disabled {
  color: white;
  background-color: $brand-light-grey;
  border-color: var(--brand-primary-lighter, $brand-primary-lighter);
}

.btn-outline-primary {
  color: var(--brand-primary, $brand-primary);
  border-color: var(--brand-primary-dark, $brand-primary-dark) ;
}

.btn-outline-primary:hover {
  color: white;
  background-color: var(--brand-primary-medium, $brand-primary-medium);
  border-color: var(--brand-primary-light, $brand-primary-light);
}

.btn-secondary {
  color: white;
  background-color: var(--brand-secondary, $brand-secondary);
  border-color: var(--brand-secondary-light, $brand-secondary-light);
  cursor: pointer;
}

.btn-secondary:hover , a:active {
  color: $white;
  background-color:var(--brand-secondary-light, $brand-secondary-light);
  border-color: var(--brand-secondary-light, $brand-secondary-light);
}

.btn-outline-secondary {
  color: var(--brand-secondary-dark, $brand-secondary-dark); //$brand-dark-grey;
  border-color: var(--brand-secondary-dark, $brand-secondary-dark); //$brand-dark-grey;
}

.btn-outline-secondary:hover {
  color: white;
  background-color: var(--brand-secondary-lighter, $brand-secondary-lighter); //$brand-light-grey;
  border-color: var(--brand-secondary-lighter, $brand-secondary-lighter); //$brand-light-grey;
}

.btn-secondary-icon{
  margin:1ex;
  cursor: pointer;
}

.btn:focus+.btn-outline-primary, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary::selection{
  box-shadow: 0 0 0 0.25rem var(--brand-primary-light, $brand-primary-light);
}

.btn-clear{
  border-radius: 0.375rem;
  padding-left: 10px;
  border-width: 1px;
  border-color: #ced4da;
}

/* dropdown */
.dropdown {
  cursor: default;
}

.dropdown-item{
  line-height: 0.6rem;
  color: black !important;
}

.dropdown-item:hover {
  background-color: var(--brand-primary-light, $brand-primary-light);
}

.dropdown-item:active{
  color: $white !important;
  background-color: var(--brand-primary-dark, $brand-primary-dark);
}

.custom-dropdown-menu {
  z-index: 1001 !important;
}

.selection {
  background-color: var(--brand-primary-light, $brand-primary-light);
}
/* datatables */

.datatable-body-row{
  margin-bottom: 1px;

}
.datatable-row-odd{
  background-color: var(--brand-table-odd, $brand-table-odd) !important;
}
.datatable-row-even{
  background-color: var(--brand-table-even, $brand-table-even) !important;
}
.datatable-body-row:hover .datatable-row-group {
  background-color: var(--brand-table-highlight, $brand-table-highlight) !important;
  cursor: pointer !important;
}
.datatable-body-row:active .datatable-row-group {
  background-color: var(--brand-primary, $brand-primary) !important;
  cursor: pointer !important;
}
.datatable-header-cell{
  margin-right: 1px;
  cursor: pointer;
  color: $brand-dark-grey !important;
}

.datatable-body-cell-label {
  white-space: nowrap;
  text-overflow: ellipsis;    // this is needed for textbaustein table
  overflow: hidden;           // cuts too long text instead of wrapping it
}
.datatable-body-cell{
  padding: .8rem !important;
}

.datatable-expand-custom{
  color: var(--brand-primary, $brand-primary);
  text-decoration: none;
  font-size: 24px;

  &:hover{
    text-decoration: none;
    color: var(--brand-primary, $brand-primary);
  }
}
.datatable-footer{
  background: var(---secondary-lighter, $brand-secondary-lighter)!important; // $brand-lighter-grey
  color: var(--brand-primary, $brand-primary) !important;
  margin-bottom: 25px;

}

.datatable-pager > ul > li > a{
  color: var(--brand-primary, $brand-primary) !important;
  &:hover{
    background: var(--brand-primary-light, $brand-primary-light)  !important;
  }
  margin-right: 1rem;
}

.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 40% !important;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  color:$brand-dark-grey
}

.active > a{
  background: $brand-lighter-grey !important;
  font-weight: bold ; //For Pager
}
.row-color{
  background: white !important;
}

// show loading indicator on bootstrap theme
.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  height: 5px;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  background-color: var(--brand-primary-light, $brand-primary-light);
  display: block;
  height: 5px;
  overflow: hidden;
  position: relative;
  transform: translate(0, 0) scale(1, 1);
  width: 100%;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  background-color: var(--brand-primary, $brand-primary);
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  width: 100%;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 0.8rem!important;
}
/* badge */
.btn .badge {
  position: relative;
  top: -1px;
  padding: 1em;
}

.btn .badge:hover , .btn .badge:active {
  border-color: $white;
  border-radius: 8rem;
}

.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem var(--brand-primary-light, $brand-primary-light);
}

.btn-check:checked+.btn-outline-primary, .btn-check:active+.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: var(--brand-primary, $brand-primary);
  background-color: var(--brand-primary-lighter, $brand-primary-lighter);
  border-color: var(--brand-primary-lighter, $brand-primary-lighter) ;
}

.btn-check:checked+.btn-outline-primary:focus, .btn-check:active+.btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--brand-primary-light, $brand-primary-light);
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--brand-primary-dark, $brand-primary-dark);
  border-color: var(--brand-primary-dark, $brand-primary-dark);
}

.align-content-center {
  //ms-flex-line-pack: center!important; // compiler warning: unknown css property
  align-content: center!important;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-error {
  color: #fff;
  background-color: red;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.8rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* form */
.form-group {
  margin-bottom: 1rem;
}

.group-for-radio label.radio-label {
  display: block;
  margin-left: 40px;
}

label {
  margin-bottom: 0.5rem;
}

.btn {
  label{
    margin-bottom: 0!important;
    margin-left: 0.5rem;
  }

}

.form-group, .control-group {
  label:not(.form-check-label, .control-checkbox),
  .label {
    color: var(--brand-dark-grey, $brand-dark-grey);
    font-weight: bold;
    margin-bottom: 5px;
  }

  legend {
    font-size: inherit;
  }
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-label {
  vertical-align: top;
}

.input-group>.form-control, .input-group-text {
  height: 36px;
}

.input-group>.form-control[type=file]{
  height: unset;
}

/*highlight colors*/
//all text input fields
.form-control:focus:not(:disabled):not([readonly]), .form-select:focus:not(:disabled):not([readonly]) {
  border-color: var(--brand-primary, $brand-primary);
  box-shadow: inset 0 1px 1px var(--brand-primary-lighter, $brand-primary-lighter) , 0 0 8px var(--brand-primary-lighter, $brand-primary-lighter) ;
}

.form-control:focus:disabled, .form-control:focus[readonly], .form-select:focus:disabled, .form-select:focus[readonly] {
  border-color: #ced4da;
  box-shadow: none;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef; //#f9f9f9;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

.form-check-input-disabled:active{
  filter: brightness(100%);
}

//in datatable header
select option:checked {
  line-height: 0.8rem;
  background: rgba(0, 0, 0, 0.3);
}

.dt-ngselect {
  transform: translateZ(0) !important;
  z-index: 1000 !important; // always on top
}
.dt-ngselect .ng-dropdown-panel {
  position: absolute !important;
  top: auto !important;
  bottom: 100% !important;
}

//all dropdown selected+highlight colors
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--brand-primary-light, $brand-primary-light);
}

//all dropdown highlight/mouse-over colors
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--brand-primary-light, $brand-primary-light);
}

//all dropdown selected colors
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--brand-primary-lighter, $brand-primary-lighter) ;
}

//all background in select boxes, elements
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--brand-primary-light, $brand-primary-light);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: unset;
}

.ng-select.form-control { transition: none !important; } // for compatibility with certain browsers

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0.5rem;
  margin-right: 0.5rem;
}

.group-for-gesperrt,
.group-for-accountLocked{
  margin-bottom: 0!important;
}

.form-check-input {
  position: relative;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  border: none;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
*, ::after, ::before {
  box-sizing: border-box;
}

input[type="radio" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  padding: initial;
  border: initial;
}


.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

/* radio buttons */
.radio-button {
  margin: 0 3px 0 15px;
}

.overlay {
  z-index: -1;
  cursor: pointer;
}

.blurred{
  filter: blur(2px);
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  z-index: 1000;
}

.overlay-content-nachrichten {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size:1.75ex;
  width: 20ex;
  transform: translate(-100%,50%);
  -ms-transform: translate(-100%,50%);
  z-index: 1000;
}

.overlay-content-nachrichten:hover{
  color: dodgerblue;
}

.infoIcon {
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: mix(indianred,white,50%);
}

.homeIcon {
  padding-left: 2ch;
  padding-right: 1ch;
}

/*input checkbox */
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 0.5em;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-line;
}

.form-check-disabled input  ~ .checkmark{
  cursor: default;
  background-color: darkgray;
}

.form-check-active input  ~ .checkmark{
  cursor: pointer;
}

.form-check-active  .checkbox-container {
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}


/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--brand-primary, $brand-primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  //-webkit-transform: rotate(45deg); "warning: unknown css property"
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* forms validation */
.form-control.ng-invalid:not(ng-select){
  border-color: $danger;
  border-width: 2px;
}

ng-select.form-control.ng-invalid > div {
  border-color: $danger;
  border-width: 2px;
}

input[type='checkbox'].ng-invalid + span {
  border: 3px solid $danger;
}

ng-select {
  border: 0 !important;
  padding: 0 !important;
}

.ng-select .ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: unset;

}

.ng-invalid:not(form):not(div):not(:disabled):not([readonly]).ng-touched {
  border-left: 5px solid #a94442; /* red */
}

.ng-valid:not(form):not(div):not(:disabled):not([readonly]).ng-touched  {
  border-left: 5px solid #42A948; /* green */
}

.ng-valid:not(form):not(div):not(:disabled):not([readonly]):focus {
  border-color: #42A948 !important;
}

.pflicht{
  color: indianred;
}

.sub-paragraph {
  margin: 0 0 20px 0;
  padding: 10px 10px 0 10px;
  border: 1px solid lightgray;
  border-radius: .25rem;
}

.sub-paragraph > div {
  margin: 0 20px 0 20px;
}

.picture-file {
  max-height: 115px;
}

.alert div {
  background-color: var(--brand-primary-light, $brand-primary-light);// #fed3d3;
  color: var(--brand-primary, $brand-primary);//#820000;
  padding: 0.5rem!important;
}

/*back button*/
.custom-card {
  background-color: var(--background-color, $background-color);
  box-shadow: 2px 1px 4px rgb(0 0 0 / 25%);
  overflow-y: auto;
}

.custom-card-wrap{
  position: relative;
  height: 100%;
}

.custom-card-back{
  position: absolute;
  left: 0;

  button{
    margin-top: 10px;
    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px;
  }
}

.custom-card-subpage{
  background-color: transparent;
  .h5{
    font-weight: normal;
    font-size: 23px;
    margin-top: 3px;
  }
  form{
    padding-bottom:25px;
  }
}

.cardAdd{
  color: var(--brand-primary, $brand-primary);
}
.cardAdd:hover{
  cursor:pointer;
}

.custom-card-btn{
  margin: 1ex;
}
.custom-card-btn:hover {
  cursor: pointer;
}

.custom-card-btn-save {
  color: palegreen;
  margin: 1ex;
}

.custom-card-btn-nachweisAdd {
  margin: 1ch;
}

.custom-card-btn-abort {
  color: palegoldenrod;
  margin: 1ex;
}

.custom-card-btn-delete {
  color: lightpink;
  margin: 1ex;
}

.custom-card-btn-overlay {
  color: white;
  margin: 1ex;
}

.custom-card-btn-calendar {
  color: dodgerblue;
  margin: 1ex;
}

.topbar {
  margin-bottom: 15px;
}

.input-currency-field{
  min-width: 3rem;
}

.custom-day {
  text-align: right;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.preselected {
  background-color: var(--brand-primary-light, $brand-primary-light);
  color: black;
}

.custom-day.preselected.selected {
  background-color: var(--brand-primary, $brand-primary);
  color: white;
}

.geschwisterInput{
  border: none;
  font-size: 75%;
}

.geschwisterInput-label{
  display: inline-block;
  font-weight: bold;
  font-size: 75%;
  border: none;
  min-width: 14ch;
}

.ngx-datatable {
  .datatable-body {
    .progress-linear {
      display: block;
      width: 100%;
      height: 8px;
      padding: 0;
      margin: 0;
      position: absolute;
      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 8px;
        transform: translate(0, 0) scale(1, 1);
        background-color: var(--brand-primary, $brand-primary);
        .bar {
          transition: all .25s linear;
          animation: query 0.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform .25s linear;
          transition: transform .25s linear;
          background-color: var(--brand-primary-light, $brand-primary-light);
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 8px;
        }
      }
    }
  }
}

.image-upload>input {
  visibility:hidden;
  width:0;
  height:0;
  cursor:pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/*thumbnails*/
.img-thumbnail{
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-content: center!important;
  overflow: hidden;
}
.img-thumbnail.large{
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 128px;
  height: 128px;
  display: flex;
  flex-wrap: wrap;
  align-content: center!important;
  overflow: hidden;
}
.img-thumbnail:hover {
  box-shadow: 0 0 2px 1px var(--brand-primary-light, $brand-primary-light);
}

// confirm modal
.confirm-modal-text {
  font-size: 14px;
}


// beleg buttons for fkUniversal-Cards
.custom-card-btn-addbeleg {
  color: black;
  margin: 1ex;
}
.custom-card-btn-addbeleg:hover {
  cursor:pointer;
}

.custom-card-btn-deletebeleg {
  color: indianred;
  margin: 1ex;
}
.custom-card-btn-deletebeleg:hover {
  cursor:pointer;
}

.custom-card-btn-morebeleg {
  color: var(--brand-primary, $brand-primary);
  margin: 1ex;
}
.custom-card-btn-morebeleg:hover {
  cursor:pointer;
}

.flex-container-right {
  display: flex;
  flex-flow: row wrap;
  /* This aligns items to the end line on main-axis */
  justify-content: flex-end;
}

.custom-card-btn-rejectbeleg {
  color: indianred;
  margin: 1ex;
}
.custom-card-btn-rejectbeleg:hover {
  cursor:pointer;
}

.modal-xl .modal-lg {
  max-width: 90%;
}

.loader {
  margin: 50px auto 0;
  border: 16px solid var(--brand-primary, $brand-lighter-grey);
  border-top: 16px solid var(--brand-primary, $brand-primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hide-but-retain-occupied-space{
  visibility: hidden;
}

.formcontrol-error-infotext {
  color: $danger;
  font-size: 90%;
  margin-top: -1em;
  margin-left: 1ex;
  padding-top: 0;
}

.countdowntimertitle{
}

.countdowntimer{
  font-weight: bold;
  font-size: large;
}

.countdowntimer2{
  font-size: medium;
}

#timeUntillLogout {
  text-align: right;
}

// css for the box that displays AntragTicket and Fahrkosten id
.id-info {
  border: 1px solid var(--brand-lighter-grey, $brand-lighter-grey);
  border-radius: 5px;
  background-color: var(--brand-secondary-light, $brand-secondary-light);
}
